<template>
  <v-row justify="center">
    <v-col cols="10" md="10" class="text-center">
      <h3 class="mb-4">
        {{ $t("general.profile") }}
      </h3>
      <p>
        {{
          $t("general.please_ensure_that_your_details_are_always_up_to_date")
        }}
      </p>
    </v-col>

    <v-col cols="10" md="10">
      <base-card>
        <v-card-text>
          <v-card-title>{{
            $t("general.personal_data_and_home_address")
          }}</v-card-title>
          <v-card-text>
            <v-select
              item-text="s_Salutation"
              item-value="s_Id"
              :label="$t('general.salutation')"
              v-model="Lo_SelectedSalutation"
              :items="La_Salutation"
              required
              :error-messages="SalutationErrors"
              @change="$v.Lo_SelectedSalutation.$touch()"
              @blur="$v.Lo_SelectedSalutation.$touch()"
            />
            <v-text-field
              v-model="Ls_Title"
              :label="$t('general.title')"
              :placeholder="$t('general.title')"
            />
            <v-text-field
              v-model="Ls_FirstName"
              :label="$t('general.firstname')"
              required
              :error-messages="FirstNameErrors"
              @blur="$v.Ls_FirstName.$touch()"
              @input="$v.Ls_FirstName.$touch()"
            />
            <v-text-field
              v-model="Ls_LastName"
              :label="$t('general.lastname')"
              required
              :error-messages="LastNameErrors"
              @input="$v.Ls_LastName.$touch()"
              @blur="$v.Ls_LastName.$touch()"
            />
            <v-text-field
              v-model="Ls_Street"
              :label="$t('general.street')"
              required
              :error-messages="StreetErrors"
              @input="$v.Ls_Street.$touch()"
              @blur="$v.Ls_Street.$touch()"
            />
            <v-text-field
              v-model="Ls_Zip"
              :label="$t('general.postcode')"
              placeholder="8104"
              required
              :error-messages="ZipErrors"
              @input="$v.Ls_Zip.$touch()"
              @blur="$v.Ls_Zip.$touch()"
            />
            <v-text-field
              v-model="Ls_City"
              :label="$t('general.city')"
              placeholder="Zürich"
              required
              :error-messages="CityErrors"
              @input="$v.Ls_City.$touch()"
              @blur="$v.Ls_City.$touch()"
            />
            <v-select
              return-object
              item-text="s_Name"
              item-value="s_Code"
              :items="La_CantonItems"
              v-model="Lo_SelectedCanton"
              :label="$t('general.canton')"
              required
              :error-messages="CantonErrors"
              @change="$v.Lo_SelectedCanton.$touch()"
              @blur="$v.Lo_SelectedCanton.$touch()"
            ></v-select>
            <v-text-field
              v-model="Ls_PhoneP"
              :label="$t('general.private_phone_number')"
              placeholder="079 525 60 00"
              required
              :error-messages="PhonePErrors"
              @input="$v.Ls_PhoneP.$touch()"
              @blur="$v.Ls_PhoneP.$touch()"
            />
            <v-text-field
              v-model="Ls_EmailP"
              type="email"
              :label="$t('general.email')"
              required
              :error-messages="EmailPErrors"
              @input="$v.Ls_EmailP.$touch()"
              @blur="$v.Ls_EmailP.$touch()"
            />
            <v-text-field
              v-model="Lt_BirthDate"
              type="date"
              :label="$t('general.date_of_birth')"
              required
              :error-messages="BirthDateErrors"
              @input="$v.Lt_BirthDate.$touch()"
              @blur="$v.Lt_BirthDate.$touch()"
            />

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ $t("general.national_insurance_number_short") }}</span>
            </v-tooltip>
            <v-text-field
              v-model="Ls_SocialNumber"
              :label="$t('general.national_insurance_number')"
              placeholder="756.8475.2396.75"
              required
              :error-messages="SocialNumberErrors"
              @input="$v.Ls_SocialNumber.$touch()"
              @blur="$v.Ls_SocialNumber.$touch()"
            />
            <v-text-field
              v-model="Ls_Employer"
              :label="$t('general.employer')"
              required
              :error-messages="EmployerErrors"
              @input="$v.Ls_Employer.$touch()"
              @blur="$v.Ls_Employer.$touch()"
            />
            <v-select
              item-text="s_Name"
              item-value="s_Value"
              :items="La_BillingItems"
              v-model="Lo_SelectedBilling"
              :label="$t('general.invoicing_of_services')"
              required
              :error-messages="BillingErrors"
              @change="$v.Lo_SelectedBilling.$touch()"
              @blur="$v.Lo_SelectedBilling.$touch()"
            ></v-select>
            <v-select
              item-text="s_Name"
              item-value="s_Id"
              :items="La_CorrespondenceLanguageItems"
              v-model="Lo_SelectedCorrespondenceLanguage"
              :label="$t('general.correspondence_language')"
              required
              :error-messages="CorrespondentLanguageErrors"
              @change="$v.Lo_SelectedCorrespondenceLanguage.$touch()"
              @blur="$v.Lo_SelectedCorrespondenceLanguage.$touch()"
            ></v-select>

            <p>{{ $t("general.mother_language") }}</p>
            <v-radio-group
              v-model="Ls_MutterLanguage"
              :error-messages="LanguageErrors"
              @change="$v.Ls_MutterLanguage.$touch()"
              @blur="$v.Ls_MutterLanguage.$touch()"
            >
              <v-radio :label="$t('general.german')" value="DE"></v-radio>
              <v-radio :label="$t('general.french')" value="FR"></v-radio>
              <v-radio :label="$t('general.italian')" value="IT"></v-radio>
            </v-radio-group>
            <p>
              {{ $t("general.second_language") }} (
              {{ $t("general.for_oral_and_written_examination") }} )
            </p>
            <v-radio-group v-model="Ls_SecondLanguage">
              <v-radio :label="$t('general.german')" value="DE"></v-radio>
              <v-radio :label="$t('general.french')" value="FR"></v-radio>
              <v-radio :label="$t('general.italian')" value="IT"></v-radio>
              <v-radio :label="$t('general.none')" value="NONE"></v-radio>
            </v-radio-group>
            <p>
              {{ $t("general.third_language") }} (
              {{ $t("general.for_oral_and_written_examination") }} )
            </p>
            <v-radio-group v-model="Ls_ThirdLanguage">
              <v-radio :label="$t('general.german')" value="DE"></v-radio>
              <v-radio :label="$t('general.french')" value="FR"></v-radio>
              <v-radio :label="$t('general.italian')" value="IT"></v-radio>
              <v-radio :label="$t('general.none')" value="NONE"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider class="mt-2" />
          <v-card-title class="mb-4">{{
            $t("general.qualification")
          }}</v-card-title>
          <v-card-text>
            <v-select
              item-text="s_Name"
              item-value="s_Id"
              :items="La_DegreeItems"
              v-model="Lo_SelectedDegree"
              :label="$t('general.degree')"
              :error-messages="DegreeErrors"
              @change="$v.Lo_SelectedDegree.$touch()"
              @blur="$v.Lo_SelectedDegree.$touch()"
            ></v-select>
            <v-text-field
              v-if="!Lb_NoDegree"
              v-model="Ls_Degree"
              :error-messages="DegreeAsErrors"
              :label="Ls_DegreeLabel"
              @input="$v.Ls_Degree.$touch()"
              @blur="$v.Ls_Degree.$touch()"
            />
            <v-text-field
              v-model="Ls_GraduationYear"
              :label="$t('general.year_of_graduation')"
              required
              :error-messages="GraduationYearErrors"
              @input="$v.Ls_GraduationYear.$touch()"
              @blur="$v.Ls_GraduationYear.$touch()"
            />
            <v-text-field
              v-model="Li_WorkExperience"
              :label="$t('general.number_of_years_of_professional_experience')"
              required
              :error-messages="WorkExperienceErrors"
              @input="$v.Li_WorkExperience.$touch()"
              @blur="$v.Li_WorkExperience.$touch()"
            />
            <v-select
              item-text="s_Name"
              item-value="s_Value"
              :items="La_ASGSWorkExperienceItems"
              v-model="Lo_SelectedASGSWorkExperience"
              :label="$t('general.work_experience_in_the_industry')"
              :error-messages="ASGSWorkExperienceErrors"
              @change="$v.Lo_SelectedASGSWorkExperience.$touch()"
              @blur="$v.Lo_SelectedASGSWorkExperience.$touch()"
            ></v-select>
            <v-text-field
              v-model="Li_WorkExperienceASGS"
              :label="
                $t(
                  'general.number_of_years_of_professional_experience_in_the_ASGS_area'
                )
              "
              required
              :error-messages="WorkExperienceASGSErrors"
              @input="$v.Li_WorkExperienceASGS.$touch()"
              @blur="$v.Li_WorkExperienceASGS.$touch()"
            />
          </v-card-text>
          <v-divider class="mt-2" />
          <v-card-title class="mt-4">{{
            $t("general.general_remarks")
          }}</v-card-title>
          <v-card-text>
            <v-textarea
              counter
              rows="2"
              row-height="1"
              v-model="Ls_CommentCandidate"
            ></v-textarea>
          </v-card-text>

          <v-divider class="mt-12" />
          <v-card-actions>
            <v-btn
              :loading="submitStatus === 'PENDING'"
              :disabled="submitStatus === 'PENDING'"
              color="primary"
              text
              @click="saveProfileData"
            >
              {{ $t("general.save") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <div>
            <v-alert type="success" v-if="submitStatus === 'OK'">
              {{ $t("general.the_entries_have_been_saved_successfully") }}
            </v-alert>

            <v-alert type="info" v-if="submitStatus === 'PENDING'">
              {{ $t("general.the_entries_are_saved") }}
            </v-alert>

            <v-alert type="error" v-if="submitStatus === 'ERROR'">
              {{ $t("errormessages.please_fill_in_all_entries_correctly") }}
            </v-alert>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import { get, post } from "../../../worker/worker-api";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  numeric,
  requiredUnless,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.$t("general.profile"),
    };
  },
  components: {},
  data() {
    return {
      submitStatus: null,
      Lo_SelectedSalutation: {
        s_Salutation: "",
        s_Id: "",
      },
      Lo_SelectedCanton: {
        s_Code: "",
        s_Name: "",
      },
      La_CantonItems: [
        { s_Code: "AG", s_Name: "Aargau" },
        { s_Code: "AR", s_Name: "Appenzell Ausserrhoden" },
        { s_Code: "AI", s_Name: "Appenzell Innerrhoden" },
        { s_Code: "BL", s_Name: "Basel-Land" },
        { s_Code: "BS", s_Name: "Basel-Stadt" },
        { s_Code: "BE", s_Name: "Bern" },
        { s_Code: "FR", s_Name: "Freiburg" },
        { s_Code: "GE", s_Name: "Genf" },
        { s_Code: "GL", s_Name: "Glarus" },
        { s_Code: "GR", s_Name: "Graubünden" },
        { s_Code: "JU", s_Name: "Jura" },
        { s_Code: "LU", s_Name: "Luzern" },
        { s_Code: "NE", s_Name: "Neuenburg" },
        { s_Code: "NW", s_Name: "Nidwalden" },
        { s_Code: "OW", s_Name: "Obwalden" },
        { s_Code: "SH", s_Name: "Schaffhausen" },
        { s_Code: "SZ", s_Name: "Schwyz" },
        { s_Code: "SO", s_Name: "Solothurn" },
        { s_Code: "SG", s_Name: "St. Gallen" },
        { s_Code: "TG", s_Name: "Thurgau" },
        { s_Code: "TI", s_Name: "Tessin" },
        { s_Code: "UR", s_Name: "Uri" },
        { s_Code: "VD", s_Name: "Waadt" },
        { s_Code: "VS", s_Name: "Wallis" },
        { s_Code: "ZG", s_Name: "Zug" },
        { s_Code: "ZH", s_Name: "Zürich" },
      ],
      Lo_SelectedBilling: {
        s_Name: "",
        s_Id: "",
      },
      Lo_SelectedCorrespondenceLanguage: {
        s_Name: "",
        s_Id: "",
      },
      Lo_SelectedDegree: {
        s_Name: "",
        s_Id: "",
      },
      Lo_SelectedASGSWorkExperience: {
        s_Name: "",
        s_Id: "",
      },
      Lb_NoDegree: true,
      Ls_Title: "",
      Ls_LastName: "",
      Ls_FirstName: "",
      Ls_Street: "",
      Ls_Zip: "",
      Ls_City: "",
      Ls_PhoneP: "",
      Ls_EmailP: "",
      Lt_BirthDate: "",
      Ls_SocialNumber: "",
      Ls_Employer: "",
      Ls_MutterLanguage: "",
      Ls_SecondLanguage: "",
      Ls_ThirdLanguage: "",
      Ls_Degree: "",
      Ls_DegreeLabel: "",
      Ls_GraduationYear: "",
      Li_WorkExperience: "",
      Li_WorkExperienceASGS: "",
      Ls_CommentCandidate: "",
    };
  },
  validations() {
    return {
      Lo_SelectedSalutation: { required },
      Ls_FirstName: { required },
      Ls_LastName: { required },
      Ls_Street: { required },
      Ls_Zip: { required },
      Ls_City: { required },
      Lo_SelectedCanton: { required },
      Ls_PhoneP: { required },
      Ls_EmailP: { required, email },
      Lt_BirthDate: { required },
      Ls_SocialNumber: { required },
      Ls_Employer: { required },
      Lo_SelectedBilling: { required },
      Lo_SelectedCorrespondenceLanguage: { required },
      Ls_MutterLanguage: { required },
      Lo_SelectedDegree: { required },
      Ls_GraduationYear: { required, numeric },
      Ls_Degree: {
        required: requiredUnless((vm) => {
          return vm.Lo_SelectedDegree == 2;
        }),
      },
      Lo_SelectedASGSWorkExperience: { required },
      Li_WorkExperience: { required, numeric },
      Li_WorkExperienceASGS: { required, numeric },
    };
  },
  watch: {
    Lo_SelectedDegree() {
      if (this.Lo_SelectedDegree == 2) {
        this.Lb_NoDegree = true;
      } else if (this.Lo_SelectedDegree == "") {
        this.Lb_NoDegree = true;
      } else if (this.Lo_SelectedDegree == 1) {
        (this.Ls_DegreeLabel = this.$t("general.EFZ_as")),
          (this.Lb_NoDegree = false);
      } else if (this.Lo_SelectedDegree == 3) {
        this.Ls_DegreeLabel = this.$t("general.degree_as");
        this.Lb_NoDegree = false;
      }
    },
  },
  computed: {
    DegreeAsErrors() {
      const errors = [];
      if (!this.$v.Ls_Degree.$dirty) return errors;
      !this.$v.Ls_Degree.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    La_Salutation() {
      return [
        {
          s_Salutation: this.$t("general.mr"),
          s_Id: "1",
        },
        {
          s_Salutation: this.$t("general.ms"),
          s_Id: "2",
        },
      ];
    },
    La_BillingItems() {
      return [
        {
          s_Name: this.$t("general.directly"),
          s_Value: "Direkt",
        },
        {
          s_Name: this.$t("general.employer"),
          s_Value: "Arbeitgeber",
        },
        {
          s_Name: this.$t("general.no_offsetting_(DO)"),
          s_Value: "keine Verrechnung (DO)",
        },
      ];
    },
    La_CorrespondenceLanguageItems() {
      return [
        {
          s_Name: this.$t("general.german"),
          s_Id: "1",
        },
        {
          s_Name: this.$t("general.french"),
          s_Id: "2",
        },
        {
          s_Name: this.$t("general.italian"),
          s_Id: "3",
        },
      ];
    },
    La_DegreeItems() {
      return [
        {
          s_Name: this.$t("general.EFZ_or_equivalent"),
          s_Id: "1",
        },
        {
          s_Name: this.$t("general.matura_technical_education_or_equivalent"),
          s_Id: "2",
        },
        {
          s_Name: this.$t("general.college"),
          s_Id: "3",
        },
      ];
    },
    La_ASGSWorkExperienceItems() {
      return [
        {
          s_Name: this.$t("general.io"),
          s_Value: "DO",
        },
        {
          s_Name: this.$t("general.sibe"),
          s_Value: "SiBe",
        },
      ];
    },
    SalutationErrors() {
      const errors = [];
      if (!this.$v.Lo_SelectedSalutation.$dirty) return errors;
      !this.$v.Lo_SelectedSalutation.required &&
        errors.push(this.$t("errormessages.salutation_is_required"));
      return errors;
    },
    FirstNameErrors() {
      const errors = [];
      if (!this.$v.Ls_FirstName.$dirty) return errors;
      !this.$v.Ls_FirstName.required &&
        errors.push(this.$t("errormessages.name_is_required"));
      return errors;
    },
    LastNameErrors() {
      const errors = [];
      if (!this.$v.Ls_LastName.$dirty) return errors;
      !this.$v.Ls_LastName.required &&
        errors.push(this.$t("errormessages.lastName_is_required"));
      return errors;
    },
    StreetErrors() {
      const errors = [];
      if (!this.$v.Ls_Street.$dirty) return errors;
      !this.$v.Ls_Street.required &&
        errors.push(this.$t("errormessages.street_is_required"));
      return errors;
    },
    ZipErrors() {
      const errors = [];
      if (!this.$v.Ls_Zip.$dirty) return errors;
      !this.$v.Ls_Zip.required &&
        errors.push(this.$t("errormessages.zip_is_required"));
      return errors;
    },
    CityErrors() {
      const errors = [];
      if (!this.$v.Ls_City.$dirty) return errors;
      !this.$v.Ls_City.required &&
        errors.push(this.$t("errormessages.city_is_required"));
      return errors;
    },
    CantonErrors() {
      const errors = [];
      if (!this.$v.Lo_SelectedCanton.$dirty) return errors;
      !this.$v.Lo_SelectedCanton.required &&
        errors.push(this.$t("errormessages.canton_is_required"));
      return errors;
    },
    PhonePErrors() {
      const errors = [];
      if (!this.$v.Ls_PhoneP.$dirty) return errors;
      !this.$v.Ls_PhoneP.required &&
        errors.push(this.$t("errormessages.phone_number_is_required"));
      return errors;
    },
    EmailPErrors() {
      const errors = [];
      if (!this.$v.Ls_EmailP.$dirty) return errors;
      !this.$v.Ls_EmailP.email &&
        errors.push(this.$t("errormessages.email_must_be_valid"));
      !this.$v.Ls_EmailP.required &&
        errors.push(this.$t("errormessages.email_is_required"));
      return errors;
    },
    BirthDateErrors() {
      const errors = [];
      if (!this.$v.Lt_BirthDate.$dirty) return errors;
      !this.$v.Lt_BirthDate.required &&
        errors.push(this.$t("errormessages.birthday_is_required"));
      return errors;
    },
    SocialNumberErrors() {
      const errors = [];
      if (!this.$v.Ls_SocialNumber.$dirty) return errors;
      !this.$v.Ls_SocialNumber.required &&
        errors.push(this.$t("errormessages.socialNumber_is_required"));
      return errors;
    },
    EmployerErrors() {
      const errors = [];
      if (!this.$v.Ls_Employer.$dirty) return errors;
      !this.$v.Ls_Employer.required &&
        errors.push(this.$t("errormessages.employer_is_required"));
      return errors;
    },
    BillingErrors() {
      const errors = [];
      if (!this.$v.Lo_SelectedBilling.$dirty) return errors;
      !this.$v.Lo_SelectedBilling.required &&
        errors.push(this.$t("errormessages.billing_is_required"));
      return errors;
    },
    CorrespondentLanguageErrors() {
      const errors = [];
      if (!this.$v.Lo_SelectedCorrespondenceLanguage.$dirty) return errors;
      !this.$v.Lo_SelectedCorrespondenceLanguage.required &&
        errors.push(
          this.$t("errormessages.correspondent_language_is_required")
        );
      return errors;
    },
    LanguageErrors() {
      const errors = [];
      if (!this.$v.Ls_MutterLanguage.$dirty) return errors;
      !this.$v.Ls_MutterLanguage.required &&
        errors.push(
          this.$t("errormessages.at_least_one_language_must_be_selected")
        );
      return errors;
    },
    DegreeErrors() {
      const errors = [];
      if (!this.$v.Lo_SelectedDegree.$dirty) return errors;
      !this.$v.Lo_SelectedDegree.required &&
        errors.push(
          this.$t("errormessages.at_least_one_degree_must_be_selected")
        );
      return errors;
    },
    GraduationYearErrors() {
      const errors = [];
      if (!this.$v.Ls_GraduationYear.$dirty) return errors;
      !this.$v.Ls_GraduationYear.numeric &&
        errors.push(this.$t("errormessages.graduation_year_must_be_a_number"));
      !this.$v.Ls_GraduationYear.required &&
        errors.push(this.$t("errormessages.graduation_year_is_required"));
      return errors;
    },
    WorkExperienceErrors() {
      const errors = [];
      if (!this.$v.Li_WorkExperience.$dirty) return errors;
      !this.$v.Li_WorkExperience.numeric &&
        errors.push(
          this.$t("errormessages.workExperience_year_must_be_a_number")
        );
      !this.$v.Li_WorkExperience.required &&
        errors.push(this.$t("errormessages.workExperience_year_is_required"));
      return errors;
    },
    ASGSWorkExperienceErrors() {
      const errors = [];
      if (!this.$v.Lo_SelectedASGSWorkExperience.$dirty) return errors;
      !this.$v.Lo_SelectedASGSWorkExperience.required &&
        errors.push(
          this.$t("errormessages.at_least_one_degree_must_be_selected")
        );
      return errors;
    },
    WorkExperienceASGSErrors() {
      const errors = [];
      if (!this.$v.Li_WorkExperienceASGS.$dirty) return errors;
      !this.$v.Li_WorkExperienceASGS.numeric &&
        errors.push(
          this.$t("errormessages.workExperienceASGS_year_must_be_a_number")
        );
      !this.$v.Li_WorkExperienceASGS.required &&
        errors.push(
          this.$t("errormessages.workExperienceASGS_year_is_required")
        );
      return errors;
    },
  },
  methods: {
    getProfileData: function () {
      get(`${process.env.VUE_APP_SMT_API_URL}/teacher/profile-data`, {
        i_AddressRoleID: this.$smt.getAddressRoleID(this.$route.meta.role),
      })
        .then((response) => {
          this.Lo_SelectedSalutation = response.i_SalutationID.toString();
          this.Ls_Title = response.s_Title;
          this.Ls_LastName = response.s_LastName;
          this.Ls_FirstName = response.s_FirstName;
          this.Ls_Street = response.s_Street;
          this.Ls_Zip = response.s_Zip;
          this.Ls_City = response.s_City;
          this.Lo_SelectedCanton = response.o_Canton;
          this.Ls_PhoneP = response.s_PhoneP;
          this.Ls_EmailP = response.s_EmailP;
          this.Lt_BirthDate = this.formatDate(response.t_BirthDate);
          this.Ls_SocialNumber = response.s_SocialNumber1;
          this.Ls_Employer = response.s_Company;
          this.Lo_SelectedBilling = response.i_Billing;
          this.Lo_SelectedCorrespondenceLanguage = response.i_SSE_LanguageID.toString();
          this.Ls_MutterLanguage = response.s_MutterLanguage;
          if (response.s_SecondLanguage === '' || response.s_SecondLanguage === 'NONE') this.Ls_SecondLanguage = 'NONE';
          else this.Ls_SecondLanguage = response.s_SecondLanguage
          if (response.s_ThirdLanguage === '' || response.s_ThirdLanguage === 'NONE') this.Ls_ThirdLanguage = 'NONE';
          else this.Ls_ThirdLanguage = response.s_ThirdLanguage
          this.Lo_SelectedDegree = response.i_Abschluss.toString();
          this.Ls_Degree = response.s_Abschluss;
          this.Ls_GraduationYear = response.i_YearAbschluss.toString();
          this.Li_WorkExperience = response.s_Berufspraxis;
          this.Li_WorkExperienceASGS = response.s_BerufspraxisAsgs;
          this.Ls_CommentCandidate = response.s_CommentCandidate;
          this.Lo_SelectedASGSWorkExperience = response.s_ASGSBranchWorkExperience;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        let month = (d.getMonth() + 1).toString().padStart(2, "0");
        let day = d.getDate().toString().padStart(2, "0");
        let year = d.getFullYear();
        return [year, month, day].join("-");
      } // if
      else {
        return "";
      } // else
    },
    saveProfileData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } // if
      else {
        this.submitStatus = "PENDING";
        post(
          `${process.env.VUE_APP_SMT_API_URL}/teacher/${this.$smt.getAddressRoleID(
            this.$route.meta.role
          )}/profile-data`,
          {
            i_SalutationID: this.Lo_SelectedSalutation,
            s_Title: this.Ls_Title,
            s_LastName: this.Ls_LastName,
            s_FirstName: this.Ls_FirstName,
            s_Street: this.Ls_Street,
            s_Zip: this.Ls_Zip,
            s_City: this.Ls_City,
            s_Kanton: this.Lo_SelectedCanton.s_Name,
            s_CantonShort: this.Lo_SelectedCanton.s_Code,
            s_PhoneP: this.Ls_PhoneP,
            s_EmailP: this.Ls_EmailP,
            t_BirthDate: this.Lt_BirthDate,
            s_SocialNumber: this.Ls_SocialNumber,
            s_Company: this.Ls_Employer,
            i_Billing: this.Lo_SelectedBilling,
            i_SSE_LanguageID: this.Lo_SelectedCorrespondenceLanguage,
            s_MutterLanguage: this.Ls_MutterLanguage,
            s_SecondLanguage: this.Ls_SecondLanguage,
            s_ThirdLanguage: this.Ls_ThirdLanguage,
            i_Abschluss: this.Lo_SelectedDegree,
            s_Abschluss: this.Ls_Degree,
            s_YearAbschluss: this.Ls_GraduationYear,
            s_Berufspraxis: this.Li_WorkExperience,
            s_BerufspraxisAsgs: this.Li_WorkExperienceASGS,
            s_CommentCandidate: this.Ls_CommentCandidate,
            s_ASGSBranchWorkExperience: this.Lo_SelectedASGSWorkExperience,
          }
        )
          .then((response) => {
            //this.getProfileData();
            this.submitStatus = "OK";
          })
          .catch((errors) => {
            console.log("submit Worker error: ", error);
          });
      } // else
    },
  },
  created() {
    this.getProfileData();
  },
};
</script>
